import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSkills, addSkills, deleteSkill } from '../../feature/profileSlice';

const Skill = ({profiles}) => {
    //state for Modal control
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //state for skill form
    const [skills, setSkills] = useState('')
    const [hasExecuted, setExecuted] = useState(false)
    

    //dispatch initialization
    const dispatch = useDispatch()

    //fetch payload
    const {skill} = useSelector((state)=>state.profile)
    const {user} = useSelector((state)=>state.auth)
    const userId = user.id

    const submitSkill = (e) => {
        e.preventDefault()
        let data ={
            skills,
            userId
        }
        dispatch(addSkills(data)).unwrap().then(()=>{
            setExecuted(!hasExecuted)
            toast.success('New skill added')
            handleClose()
        }).catch(toast.error)
    }

    //delete a skill
    const removeSkill =(value)=>{
        dispatch(deleteSkill(value)).unwrap().then(()=>{
            setExecuted(!hasExecuted)
            toast.success('Skill removed')
        }).catch(toast.error)
        
    }

    useEffect(async()=>{
        await dispatch(getSkills())
    },[dispatch, hasExecuted])
  return (
    <>
    <div className='mt-3 bg-white p-4 border border-muted'>
    <h4 className='fw-bold text-muted'>
                    Skills{' '}
                    <i
                        className='fa fa-plus text-danger mx-2 my-2'
                        style={{ cursor: 'pointer' }}
                        onClick={handleShow}
                    ></i>
                </h4>
        <div className='card-body'>
            <Row>
            {skill == undefined ? <div>No skill history added</div> ? !skill : 'loading...'   : skill.map(items =>(
                
                <div className='mb-2 col-md-6 col-sm-12'  key={items.id}>
                    <p className='lead text-danger bg-light m-0 text-center rounded' >{items.skills} <span><i
                        className='fa fa-minus text-danger mx-2 my-2'
                        style={{ cursor: 'pointer' }}
                        onClick={()=>removeSkill(items.id)}
                    ></i></span></p>
                </div>
                
            ))}
               
               
                
            </Row>
       
        </div>
    </div>
     {/* Add skills */}
     <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Professional Skill</Modal.Title>
                </Modal.Header>
                <Form>
                <Modal.Body>
                <div className='container'>
                    <div className='form-group mb-3'>
                        <label className='form-label lead fs-6 text-danger'>Skill Name</label>
                        <input type='text' placeholder='Skill' className='form-control' required onChange={(e)=>setSkills(e.target.value)} />
                    </div>

                    
                </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="primary" onClick={submitSkill}>
                        Save Changes
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
    </>
  )
}

export default Skill