import React, { useState } from 'react'
import { Button, Card, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getTitle, addTitle, getUserProfile } from '../../feature/profileSlice'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import CardHeader from 'react-bootstrap/esm/CardHeader'


function Profile({ profiles }) {
    //payload
    const { titles, userProfile  } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)
 

    const dispatch = useDispatch()

    //state for adding title
    const [title, setTitile] = useState('')
    const [about, setAbout] = useState('')
    const [hasExecuted, setExecuted] = useState(false)
    const userId = user.id



    //for modals
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
        setTitile(titles.title)
        setAbout(titles.about)

    }
    //submit profile edit
    const submitTitle = (e) => {
        e.preventDefault()
        let data = {
            title,
            about,
            userId,
        }
        dispatch(addTitle(data))
        handleClose()
        setExecuted(!hasExecuted)
        toast.success('Profile Updated')
    }

    useEffect(() => {
        async function titleGet(){
            await dispatch(getTitle()) 
        }
       titleGet()
    }, [dispatch, hasExecuted])

     //fetching userProfile
 useEffect( () => {
    async function getProfiles(){
        await dispatch(getUserProfile())
    }
   getProfiles()
}, [dispatch, hasExecuted])

    const users = !userProfile ? '': userProfile[0]


    return (
        <>
           
                <div className="d-flex align-items-center mb-4">
                    <div className="flex-shrink-0">
                        <div className='big-circle'>
                            {(profiles.firstName).charAt(0)}
                            {' '}
                            {(profiles.lastName).charAt(0)}
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <div className="d-flex flex-row align-items-center mb-0">
                            <h3 className='display-5 fw-bold text-muted'>
                                {profiles.firstName}
                                {' '}
                                {profiles.lastName}
                            </h3>
                        </div>
                        <div className='p-0 m-0'>
                        <p><span className='lead fw-bold text-danger'>{!titles ? '' : titles.title}</span> | {profiles.email} | {users.phone}</p>
                        </div>
                        <div className='p-0 m-0'>
                        <p>{users.nationality} | {users.city} | {users.gender}</p>
                        </div>

                    </div>
                </div>
                <div className="hr border border-muted"></div>
              <div className='mt-4'>
                        <h4 className='fw-bold text-secondary'>Short Bio <i className='fa fa-edit text-danger' style={{cursor:'pointer'}} onClick={handleShow}></i></h4>           
                        <p>{!titles ? '' : titles.about}</p>
              </div>
              <div className="hr border border-muted"></div>
                  
          
          
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>About You</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className='container'>
                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Title</label>
                                <input type='text' placeholder='Professional Title e.g Chief Accountant' value={title} onChange={(e) => setTitile(e.target.value)} className='form-control' required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Brief note about you</label>
                                <textarea className='form-control' placeholder='About you' value={about} onChange={(e) => setAbout(e.target.value)}></textarea>
                            </div>

                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type='submit' variant="primary" onClick={submitTitle}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default Profile

