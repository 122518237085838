import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LogoHeader from '../components/LogoHeader'
import RecentJobSlider from '../components/RecentJobSlider'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Slider from '../components/Slider'
import { useDispatch, useSelector } from 'react-redux'
import { getJobs, getCategory} from '../feature/webSlice'
import MainChat from '../components/chat/MainChat'


const Home = () => {
  


  const { jobs, category } = useSelector((state)=>state.web)
  const {user} = useSelector((state)=>state.auth)

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData(){
     await dispatch(getJobs({pageSize: 20}))
     await dispatch(getCategory({pageSize: 3}))
    }
    fetchData()
    
  }, [dispatch]);
  return (
    <>
  
      <Header />
     
      <Slider data4={category} /> 
      <section id='job-process'>
        <img src='/images/ril-job.png' alt='job process' width='100%' height='auto' />
      </section>
      <section id='content-home' className='mt-5'>
        <div className='container'>
          <h1 style={{ fontSize: '3.5rem', fontWeight: 'bolder' }}>Welcome to our <span className='text-danger'>Talent</span></h1>
          <h1 style={{ fontSize: '3.5rem', fontWeight: 'bolder' }}>Placement Portal</h1>
        </div>

      </section>

      <section id='text-section' className='mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 col-lg-3 col-sm-12 pr-3 d-inline-flex'>
              <p>At Resource Intermediaries Limited, we offer full-service recruitment solutions to Employers and Jobseekers.
                We collaborate with national employers to provide a diverse spectrum of specialized services and meet their human resource and labor requirements through our head office in Lekki, Lagos Nigeria, and representative offices across Nigeria and Ghana.
              </p>

            </div>

            <div className='col-md-3 col-lg-3 col-sm-12 pr-3'>
              <p>RIL also provides expatriate recruitment services, employing our respected network and recruitment consultants. Our services start right here in Nigeria, where we assist national employers with labor scarcity solutions. Our international recruitment strategy includes strategic processes, partner agencies, and advisers to attract and hire qualified foreign professionals.</p>

            </div>

            <div className='col-md-3 col-lg-3 col-sm-12'>
              <div className="card" >
                <img src="/images/profile.png" className="card-img-top img-fluid" alt="..." />
                <div className="card-header  pt-3 pb-0" style={{ background: '#770f13' }}>
                  <Link to={'/auths'}><p className="card-link text-white ">Create your Profile<span className='float-end'> <i className='fa-solid fa-angle-right'></i></span></p></Link>
                </div>
              </div>
            </div>

            <div className='col-md-3 col-lg-3 col-sm-12 pt-2'>
              <div className="card" >
                <img src="/images/cv.png" className="card-img-top img-fluid" alt="..." style={{ height: '100%' }} />
                <div className="card-header  pt-3 pb-0" style={{ background: '#770f13' }}>
                  <Link to={'/profile'}><p className="card-link text-white ">Upload Your CV<span className='float-end'> <i className='fa-solid fa-angle-right'></i></span></p></Link>
                </div>
              </div>
            </div>



          </div>

        </div>

      </section>
     
      
      <RecentJobSlider data={jobs} />
      {user ? 
      <MainChat />
      :''
      }
      <Footer />
      
    </>
  )
}

export default Home