import React from 'react'
import Logo from '../components/Logo'
import TopBar from '../components/TopBar'
import { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { AuthContext } from '../helpers/AuthContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import LogoHeader from '../components/LogoHeader'
import Header from '../components/Header'
// Axios.defaults.withCredentials = true; 

const LoginReg = () => {
    
    
    let history = useNavigate();

    //check if user loggen in 
    useEffect(() => {
      Axios
        .get("https://backend.nochsolutions.com.ng/users/auth", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          if (response.data.error) {
            setAuthState({ ...authState, status: false })
            history("/auths")

          } else {
            
            setAuthState({
              email: response.data.email,
              id: response.data.id,
              first_name:response.data.first_name,
              status: true,
            });
            history("/")
          }
        });
    }, []);
  




    const [email, setEmail] = useState('')
    const [password, setPass] = useState('')
    const [last_name, setLastName] = useState('')
    const [first_name, setFirstName] = useState('')


    const [authState, setAuthState] = useState({
        email: "",
        id: 0,
        first_name: "",
        status: false,
      });

    
    //Register
    const register = () =>{
        Axios.post(`https://backend.nochsolutions.com.ng/users/add-user`,{
            email: email,
            password: password,
            first_name: first_name,
            last_name:last_name
        }).then((response) => {
            //toast.success("Registration complete")
            console.log(response)
            
           
        })

   }




   //Login
   const login = () =>{
       const data={email:email, password:password}
    Axios.post("https://backend.nochsolutions.com.ng/users/login",data).then((response) => {
        //toast.success("Registration complete")
        if(response.data.error){
            toast.error(response.data.error)
        }
            else {
                localStorage.setItem("accessToken", response.data.token)
                setAuthState({
                  email: response.data.email,
                  id: response.data.id,
                  first_name: response.data.first_name,
                  status: true,
                })
                //console.log(authState)
                history("./")
                
        }
  
    })

}
    // const [loginStatus, setLoginStatus] = useState("");
    // useEffect(() => {
    //     Axios.get("https://backend.nochsolutions.com.ng/users/login").then((response) => {
    //       if (response.data.loggedIn === true) {
    //         setLoginStatus(response.data.user[0].email);
    //         console.log(loginStatus)
    //       }
    //     });
    //   }, [loginStatus]);

  return (
      
 <>

<Header /> 

 <div className="container mt-5">
     <div className='offset-md-3 col-md-6 col-sm-12 col-lg-6'>
     <div className='row'>
    <div className="card">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item text-center"> <a className="nav-link active btl" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Login</a> </li>
            <li className="nav-item text-center"> <a className="nav-link btr" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Signup</a> </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="form px-4 pt-5"> 
                <input type="text" name="email" className="form-control" onChange={(e) => {
                  setEmail(e.target.value)
                }}  placeholder="Email" /> 
                <input type="password" name="password" onChange={(e) => {
                  setPass(e.target.value)
                }}  className="form-control" placeholder="Password" /> 
                <button className="btn btn-dark btn-block" onClick={login} >Login</button> </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="form px-4"> 
                <input type="text" name="first_name" onChange={(e) => {
                  setFirstName(e.target.value)
                }}  className="form-control" placeholder="First Name" /> 
               

               <input type="text" name="last_name" onChange={(e) => {
                  setLastName(e.target.value)
                }}  className="form-control" placeholder="Last Name" /> 


                <input type="text" name="email" onChange={(e) => {
                  setEmail(e.target.value)
                }}  className="form-control" placeholder="Email" /> 


                <input type="text" name="password" onChange={(e) => {
                  setPass(e.target.value)
                }} 
                
                className="form-control" placeholder="Password" /> <button className="btn btn-dark btn-block" onClick={register}>Signup</button> </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<Footer />
</>
   
  )
}

export default LoginReg