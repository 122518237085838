import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import JobHeader from '../components/jobCard/JobHeader'
import Profile from '../components/jobCard/Profile'
import { Card, Col, Row } from 'react-bootstrap'
import PersonalInfo from '../components/jobCard/PersonalInfo'
import Experience from '../components/jobCard/Experience'
import Education from '../components/jobCard/Education'
import Skill from '../components/jobCard/Skill'
import ApplyJob from '../components/jobCard/ApplyJob'
import Certification from '../components/jobCard/Certifications'
import Resume from '../components/jobCard/Resume'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile } from '../feature/profileSlice'
import { selectisLoggedIn, googlLogin } from '../feature/authSlice'
import Spinner from '../components/Spinner'
import { decodeToken } from "react-jwt";
const JobProfile = () => {
 //use selector to get payloads
const isLoggedIn = useSelector(selectisLoggedIn)
const{userProfile} = useSelector((state) => state.profile)
const dispatch = useDispatch()
const navigate = useNavigate()




//check if login with social
// Read the JWT token from the URL query parameter
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

// useEffect(async () => {
//   try {
//     const cookieValue = document.cookie.replace(
//       /(?:(?:^|.*;\s*)user\s*\=\s*([^;]*).*$)|^.*$/,
//       '$1'
//     );

//     if (cookieValue !== undefined && cookieValue.trim() !== '') {
//       const decodedCookieValue = decodeURIComponent(cookieValue);
//       console.log(cookieValue);
//       try {
//         const data = JSON.parse(decodedCookieValue);
//         await dispatch(googlLogin(data));
//         navigate('/userProfile');
//       } catch (parseError) {
//         console.error('Error parsing JSON:', parseError);
//       }
      
    
//     }
//   } catch (error) {
//     // Handle any errors that occur during the process
//     console.error('An error occurred:', error);
//   }
// }, [dispatch, navigate]);

if(token){
  try {
    const decoded= decodeToken(token);
    const data = {
      id: decoded.id,
      firstName: decoded.firstName,
      email: decoded.email,
      token: token,
    };
    dispatch(googlLogin(data))
    navigate('/userProfile')
  } catch (error) {
    console.log(error)
  }
 
}


useEffect(()=>{
  async function fetch(){
      await dispatch(getUserProfile());
  }
   fetch(); 
},[dispatch])

 
if(!userProfile){
  return <Spinner />
}

  const profiles = userProfile[0]




       
    
  return (
    <>
    <JobHeader />
    <div className='container mt-5'>
    <Row>
    <Col md={8} sm={12}>
    <Profile profiles={profiles} /> 
    
    <Experience profiles={profiles} />
    <Certification profiles={profiles} />
   
    
   
    
    
    </Col>
    <Col md={4} sm={12}> 
    <PersonalInfo />
    <Skill />
      
       <Education profiles={profiles} />
       
       
        <ApplyJob profiles={profiles} />
    </Col>
    </Row>
    </div>

    <Footer />
    </>
  )
}

export default JobProfile