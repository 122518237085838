import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { useDispatch, useSelector } from 'react-redux'
import { appliedjob } from '../../feature/profileSlice'
import Spinner1 from '../Spinner1'

const ApplyJob = () => {
    const {user} = useSelector((state)=>state.auth)
    const {applied} = useSelector((state)=>state.profile)

    const userId = user.id
    const dispatch = useDispatch()

    useEffect( ()=>{
     async function fetchCert(){
       await dispatch(appliedjob())
     }    
     fetchCert()  
    },[dispatch])

    console.log(applied)



  return (
    <>
    <Card className='job-card mb-4 mt-4 border-0'>
        <CardHeader className='bg-light border-0'>
            <div className='d-flex float-start'>
                Recent Application
            </div>
            
            
        </CardHeader>
        <div className='card-body'>
            {!applied ? 'loading...' : applied.totalItems === 0 ? <div>you have no job application history</div> : applied.data.rows.map((items)=>(
                 <Row>
                 <Col md={12} sm={12} className='mb-2'>
                     <p className='lead fw-bold m-0'>{items.jids.company}</p>
                     <small className='text-muted'>{items.jids.jobTitle} <span className='bg-light text-danger d-flex float-end'>{items.applicantStatus}</span> </small>
                 </Col>
                 <hr className='border-danger' /> 
                 </Row>

            ))}
           
                

               
               
                
           
       
        </div>
    </Card>
    </>
  )
}

export default ApplyJob