import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"



//get main link
const url = process.env.REACT_APP_BACKEND


const initialState = {
    jobs:null,
    category:null,
    singleJob:null,
    catJobs:null,
    search:null,
    loading:false,
    jobCheck:null,
  }

  //send feedback
  export const sendFeedback = createAsyncThunk(
    'users/sendFeedback',
    async(data, thunkAPI)=>{
      try {
        const token = thunkAPI.getState().auth.user.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
        const response = await axios.post(`${url}/users/feedbacks`, data, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
    }
  )

 
  //check Job
  export const checkJob = createAsyncThunk(
    'users/checkJob',
    async(data, thunkAPI)=>{
      try {
        const token = thunkAPI.getState().auth.user.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
        const response = await axios.post(`${url}/users/checkJob`, data, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
    }
  )

  //searchjob
  export const jobSearch = createAsyncThunk(
    'users/jobSearch',
    async(datas, thunkAPI)=>{
      try{
        const response = await axios.get(`${url}/users/searchJob?keywords=${datas.keywords}&location=${datas.locations}`)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))

      }
    }
  )


  //applywithCv
  export const applyByCv = createAsyncThunk(
    'users/applyByCv',
    async(data, thunkAPI)=>{
      try{
        const token = thunkAPI.getState().auth.user.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
        return await axios.post(`${url}/users/applyWithCv`, data, config)

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))

      }
    }
  )


  //get Jobs
export const getJobs = createAsyncThunk(
  'users/getJobs',
 
  async (params, cat, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const response = await axios.get(`${url}/users/getJobs?page=${page}&pageSize=${pageSize}` )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  });


    //get Categories
export const getCategory = createAsyncThunk(
  'users/getCategory',
  async (params, cat, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
    
      const response = await axios.get(`${url}/admin/getCategory?page=${page}&pageSize=${pageSize}`)
      return response.data.data.rows
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  });


      //get singleJob
export const getSingleJob = createAsyncThunk(
  'users/getSingleJob',
  async (id, thunkAPI) => {
    try {
    
      const response = await axios.get(`${url}/admin/singleJob/${id}`)
      return response.data[0]
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  });

//get job by Categories
export const getJobByCategory = createAsyncThunk(
  'users/getJobByCategory',
  async (params, cat, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
    
      const response = await axios.get(`${url}/admin/getJobByCategory/${cat}?page=${page}&pageSize=${pageSize}`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  });

  export const applyByProfile = createAsyncThunk(
    'users/applyByProfile',
    async(data, thunkAPI)=>{
      try{
        const token = thunkAPI.getState().auth.user.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.post(`${url}/users/applyWithProfile`, data, config)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))

      }
    }
  )

  




  
export const webSlice = createSlice({
    name: 'web',
    initialState,
  
    reducers: {
    
    },
    extraReducers: (builder) => {
      (builder)
  
        .addCase(getJobs.fulfilled, (state, action) => {
          state.jobs = action.payload
        })
        .addCase(getCategory.fulfilled, (state, action)=>{
          state.category = action.payload
        })  
        .addCase(getSingleJob.fulfilled, (state, action) =>{
          state.singleJob = action.payload
        })
        .addCase(getJobByCategory.fulfilled, (state, action)=>{
          state.catJobs = action.payload
        })
        .addCase(jobSearch.fulfilled, (state, action)=>{
          state.search = action.payload
        })
        .addCase(applyByCv.pending, (state)=>{
          state.loading = true
        })
        .addCase(applyByCv.fulfilled, (state)=>{
          state.loading= false
        })
        .addCase(checkJob.fulfilled, (state, action)=>{
          state.jobCheck = action.payload
        })
  
    }
  })

   export default webSlice.reducer