import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ScrollToBottom from "react-scroll-to-bottom";
import { addMessage, getMessages } from '../../feature/chatSlice';
import io from "socket.io-client"
import Spinner1 from '../Spinner1'


const MainChat = () => {
  const { user } = useSelector((state) => state.auth)
  const [inputMessage, setInputMessage] = useState('');

  
  const [arrivalMessage, setArrivalMessage] = useState(null)
  const { chats } = useSelector((state) => state.chat)  
 const [chatHistory, setChatHistory] = useState([])
  const dispatch = useDispatch()
  const email = user.email
  const socket = useRef()
  const url = process.env.REACT_APP_END
  const [admins, setAdmins] = useState('')

//set admin email
  const handleConnect = (values) => {
    setAdmins(values)
    
  };
console.log(admins)
  //useEffect for socket
  useEffect(()=>{
    if(admins !==''){
      socket.current = io("https://rilats.com.ng", {
        withCredentials: false
      })
      // socket.current=io("http://localhost:9000")
      // socket.current.emit("addUser", email)
    }
  },[admins])

  // Listen for incoming messages and update the chat history
  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-received", (msg) => {
        console.log(msg)
        setArrivalMessage(msg); // store the received message in arrivalMessage state
      });
    }
  }, []);

  //fetch chats 
  useEffect(() => {

      let data = {
      senderId: email,
      recieverId: admins
    }
    dispatch(getMessages(data))

  }, [dispatch, admins]);

    //  //set payload in local state
    useEffect(()=>{
      if(chatHistory != chats){
        setChatHistory(chats)
      }
    },[chats])
 

//submit chat
  const handleSubmit = (event) => {
    event.preventDefault();

     const data = {
      senderId:email,
      recieverId:admins,
      text:inputMessage
    }
    if (inputMessage !== '') {
      socket.current.emit("send-msg", {
        recieverId:admins,
        senderId:email,
        text:inputMessage
      })
      setChatHistory(chatHistory.concat(data))
      dispatch(addMessage(data))
      setInputMessage('');
      
    }
    
  };


  //fetch socket messages
  useEffect(() => {
    if (arrivalMessage) {
      setChatHistory(chatHistory.concat(arrivalMessage))
    }
  }, [arrivalMessage]);



  
// if(!chatHistory)
// return (<Spinner1 />)

console.log(chatHistory)

  return (
    <>
    <div className='d-flex float-right'>
    <div className='chats p-3'>
      <input type="checkbox" id="check" />
      <label className="chat-btn" htmlFor="check">
        <i className="fa fa-commenting-o comment"></i>
        <i className="fa fa-close close"></i>
      </label>
      <div className="wrapper">
        <div className="header">
          <h6>Let's Chat - Online</h6>

        </div>
        {admins ?
          <div className='chats'>

            <div className='chat-body p-2 mt-5'>
              <ScrollToBottom className="message-container">
                <div className="text-center p-2">
                  {chatHistory && chatHistory.map((items) => (
                    
                    items.senderId === email ?
                      <div className='my-content' key={items.id}>
                        {items.text}
                      </div>
                      :
                      <div className='your-content'>
                        {items.text}
                      </div>

                  ))}
                </div>
              </ScrollToBottom>
            </div>

            <div className="chat-footer">

              <input type="text" className="form-control" value={inputMessage} placeholder="Message" onChange={(e) => setInputMessage(e.target.value)} />
              {/* <button className="btn btn-block" onClick={sendMessage}>Send</button>  */}
              <button className="btn btn-block" onClick={handleSubmit} >Send</button>
            </div>
          </div>
          :
          <div className='chat-body p-2 mt-5'>
            <p>Hi {user.firstName}</p>
            <p>We are glad you want to talk to us, please click the button below to connect to our customer service agent</p>
            <div className="text-center p-2 mt-5">
              {/* {reply} */}
                {/* <input type='hidden' value={admin} onChange={(e)=>setAdmins(e.target.value)} /> */}
              <button className='btn btn-danger mt-3' onClick={()=>handleConnect("chatril@resourceintermediaries.org")}>Click here to start chatting</button>
            </div>

          </div>
        }
      </div>
    </div>
    </div>
    </>
  )
}


export default MainChat