import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getEducation, singleEdus, updateEdus } from '../../feature/profileSlice';
import moment from 'moment';
import Footer from '../Footer';
import JobHeader from './JobHeader';
import Spinner1 from '../Spinner1';

const AllEducation = () => {

    //fetch state with payload
    const { education, singleEdu } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)

    //states to add education form
    const [institution, setSchool] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [grade, setGrade] = useState('')
    const [course, setCourse] = useState('')
    const [loading, setLoading] = useState(false)
    const userId = user.userId


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = async (ids) => {
        try {
            const result = await dispatch(singleEdus(ids)).unwrap();
            setSchool(singleEdu[0].institution)
            setGrade(singleEdu[0].grade)
            setCourse(singleEdu[0].course)
            setStartYear(singleEdu[0].startYear)
            setEndYear(singleEdu[0].endYear)
            setShow(true); // Set showModal to true to show the modal
          } catch (error) {
            console.error('Error fetching data:', error);
          }

    }


    //dispatch
    const dispatch = useDispatch()




    //add new education
    const submitEducation = (ids) => {
        let data = {
            institution,
            startYear,
            endYear,
            grade,
            course,
            id: ids
        }
        dispatch(updateEdus(data)).unwrap().then(() => {
            toast.success('Education updated successfully')
            window.location.reload(false)
            handleClose()
        }).catch(toast.error)
    }

    useEffect(async () => {
        await dispatch(getEducation())
    }, [dispatch])
    if (!education)
        return (
            <Spinner1 />
        )

    return (
        <>
            <JobHeader />
           
            <div className='container'>
                <a href='/userProfile' className='lead mb-4 text-danger'><i className='fa fa-arrow-left'></i> back to profile</a>
                <Row className='mt-5'>
                    <Col md={8} sm={12}>
                        <h3 className='display-6 mb-4 text-muted'>Education</h3>
                        <Card className='job-card mb-4 border-0'>

                            <div className='card-body'>
                                <Row>

                                    {education == undefined ? <div>No job history added</div> ? !education : 'loading...' : education.map(items => (
                                        <div key={items.id}>
                                            <Col md={12} sm={12} className='mb-2'>
                                                <div className='d-flex float-end'>
                                                    <button className='btn btn-sm btn-outline-danger' onClick={() => handleShow(items.id)}> <i className='fa fa-edit'></i></button>
                                                </div>
                                                <p className='lead fw-bold m-0' >{items.institution}</p>
                                                <small className='text-muted' >{items.course}<span className='bg-light text-danger'> ({moment(items.startYear).format('YYYY')} - {moment(items.startYear).format('YYYY')}) </span></small>
                                            </Col>

                                        </div>
                                    ))}

                                </Row>

                            </div>
                        </Card>
                        <Modal show={show} onHide={handleClose}>
                            
                                <><Modal.Header closeButton>
                                    <Modal.Title>Edit Experience </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                {!singleEdu ? <Spinner1 /> :
                                        <div className='container'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label lead fs-6 text-danger'>Institution Name</label>
                                                <input type='text' placeholder='Name of Institution' className='form-control' value={institution} onChange={(e) => setSchool(e.target.value)} />
                                            </div>

                                            <div className='form-group mb-3'>
                                                <label className='form-label lead fs-6 text-danger'>Course of study</label>
                                                <input type='text' placeholder='Course of study' value={course} onChange={(e) => setCourse(e.target.value)} className='form-control' />
                                            </div>

                                            <div className='form-group mb-3'>
                                                <label className='form-label lead fs-6 text-danger'>Start Date</label>
                                                <input type='date' placeholder='Start Date' className='form-control' value={moment(startYear).format('YYYY-MM-DD')} onChange={(e) => setStartYear(e.target.value)} />
                                            </div>

                                            <div className='form-group mb-3'>
                                                <label className='form-label lead fs-6 text-danger'>End Date</label>
                                                <input type='date' placeholder='End Date' className='form-control' value={moment(endYear).format('YYYY-MM-DD')} onChange={(e) => setEndYear(e.target.value)} />
                                            </div>

                                            <div className='form-group mb-3'>
                                                <label className='form-label lead fs-6 text-danger'>Grade</label>
                                                <input type='text' placeholder='Grade' className='form-control' value={grade} onChange={(e) => setGrade(e.target.value)} />
                                            </div>


                                        </div>
                                        }

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={() => submitEducation(singleEdu[0].id)}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer></>
                            
                        </Modal>
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}

export default AllEducation