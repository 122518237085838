import React from 'react'
import { Link } from 'react-router-dom'
import MainChat from './chat/MainChat'



const Slider = ({data4}) => {
 
  
  return (
<div className='slide-form ml-2 mr-2 pl-3 pr-3 '>
<h1 className='text-center text-white mt-5 pt-5'>Join us and Explore thousands of job</h1>
<p className='text-white text-center'>Select your industry and/or r type your keyword, then click search to find your perfect job</p>
<div className='container pt-5 pb-5' id='slider-container'>
<div className='row'>
    <div className='col-md-10 offset-md-1'>
<form className="form-inline mt-4" action='/Job'>

<div className='row'>
  <div className="form-group col-md-5 col-sm-12 mb-2 col-form-label">
       <input type="text" className="form-control pt-3 pb-3 form-control-lg" size="200" name='keyword' id="job1" placeholder="Keyword" />
  </div>
  <div className="form-group col-md-5 col-sm-12 mb-2 col-form-label">
      <input type="text" name='location'  className="form-control pt-3 pb-3 form-control-lg" id="job2" placeholder="Location" />
  </div>

  <div className="form-group col-md-2 mb-2 col-form-label">
  <button type="submit" className="btn btn-danger btn-lg btn-block mb-2  pt-3 pb-3"><i className="fa-solid fa-magnifying-glass"></i> Search</button>
  </div>

  </div>
 

</form>
<hr style={{ height:'3px', width:'100%', borderWidth:'0',  backgroundColor:'#fff' }}></hr>
<div>
  <div className='row'>
    <div className='col-md-3 col-sm-12 col-lg-3'>
<span style={{ fontWeight:'900', fontSize: '2rem' }} className='text-white'>Quick Search:</span>  
    </div>    

            {!data4 ? '' : data4.map(item => <div key={item.id} className='col-sm-12 col-md-3 col-lg-3'> <Link className='text-white pl-5' to={`/joblist?category=${item.category}`}> <span style={{ fontWeight:'400', fontSize: '2rem' }} >{item.category}</span>  </Link></div>)}

 </div>           
</div>
</div>
</div>

            </div>
            <div>
       
       
       
   </div>
        </div>

    
  )
}

export default Slider