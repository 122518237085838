import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getEducation, addEducation } from '../../feature/profileSlice';
import moment from 'moment';

const Education = ({ profiles }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //fetch state with payload
    const { education } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)
    //dispatch
    const dispatch = useDispatch()

    //states to add education form
    const [institution, setSchool] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [grade, setGrade] = useState('')
    const [course, setCourse] = useState('')
    const [hasExecuted, setExecuted] = useState(false)
    const userId = user.id


    //add new education
    const submitEducation = (e) => {
        e.preventDefault()
        let data = {
            institution,
            startYear,
            endYear,
            grade,
            course,
            userId
        }
        dispatch(addEducation(data)).unwrap().then(() => {
            toast.success('Education added successfully')
            setExecuted(!hasExecuted)
            handleClose()
        }).catch(toast.error)
    }

    useEffect(() => {
        async function fetchEdu() {
            await dispatch(getEducation())
        }
        fetchEdu()
    }, [dispatch, hasExecuted])


    return (
        <>
            <div className='mt-3 bg-white p-4 border border-muted'>
                <h4 className='fw-bold text-muted'>
                    Educations{' '}
                    <i className='fa fa-plus text-danger mx-2 my-2' onClick={handleShow}></i>

                    <a href='/education'> <i className='fa fa-edit text-danger mx-2 my-2'></i></a>
                </h4>
               
        <div className='mt-4'>
                {!education ? (
                    'loading...'
                ) : education.length === 0 ? (
                    <div>No education history added</div>
                ) : (
                    education.map((items) => (
                        <div className="d-flex mb-4" key={items.id}>
                            <div className="flex-shrink-0">
                                <div className='big-circle1'>
                                    <i className='fa fa-graduation-cap'></i>
                                </div>
                               
                            </div>
                            <div className="flex-grow ms-3">
                                <div className="flex-column  mb-0">
                                    <p className=' m-0'><span className='h4 fw-bold text-danger'>{items.institution} </span></p>
                                    
                                </div>
                                <div className="mt-2">
                                    <p className='text-muted'>{items.course} &nbsp; <span className='text-danger float-end'>
                                            {moment(items.startYear).format('YYYY')} - {moment(items.startYear).format('YYYY')}
                                        </span></p>
                                                                        
                                </div>
                            </div>
                        </div>
                    ))
                )}
                </div>
            </div>

            {/* <Card className='job-card mb-4 border-0'>
        <CardHeader className='bg-light border-0'>
            <div className='d-flex float-start'>
                Education
            </div>
            <div className='d-flex float-end btn-group'>
                      <i className='fa fa-plus text-danger mx-2 my-2' onClick={handleShow}></i>

                        <a href='/education'> <i className='fa fa-edit text-danger mx-2 my-2'></i></a>
                    </div>
        </CardHeader>
        <div className='card-body'>
            <Row>
           
            {!education ? 'loading...' : education.length === 0 ? <div>No education history added</div>  : education.map(items =>(
                <div key={items.id}>
                <Col md={12} sm={12} className='mb-2'>
                    <p className='lead fw-bold m-0' >{items.institution}</p>
                    <small className='text-muted' >{items.course}<span className='bg-light text-danger'> ({moment(items.startYear).format('YYYY')} - {moment(items.startYear).format('YYYY')}) </span></small>
                </Col><hr className='border-danger' />
                </div>
            ))}
            
                
               
                
            </Row>
       
        </div>
    </Card> */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Education</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Institution Name</label>
                            <input type='text' placeholder='Name of Institution' className='form-control' onChange={(e) => setSchool(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Course of study</label>
                            <input type='text' placeholder='Course of study' onChange={(e) => setCourse(e.target.value)} className='form-control' />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Start Date</label>
                            <input type='date' placeholder='Start Date' className='form-control' onChange={(e) => setStartYear(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>End Date</label>
                            <input type='date' placeholder='End Date' className='form-control' onChange={(e) => setEndYear(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Grade</label>
                            <input type='text' placeholder='Grade' className='form-control' onChange={(e) => setGrade(e.target.value)} />
                        </div>


                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submitEducation}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Education