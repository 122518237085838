import React from 'react'

const Footer = () => {
  return (
    <>
<section className="footer pt-5 shadow mt-5 bg-dark text-white pb-0">
    <div className="container">
        <div className="row pb-2">
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                <img src="/images/logo.png" alt="" height='80px' />
                
                    <p>At Resource Intermediaries Limited, we offer full-service recruitment solutions to Employers and Jobseekers.
We collaborate with national employers to provide a diverse spectrum of specialized services and meet their human resource and labor requirements through our head office in Lekki, Lagos Nigeria, and representative offices across Nigeria and Ghana. RIL also provides expatriate recruitment services, employing our respected network and recruitment consultants.
</p>
                
            </div>
            
            <div className="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <h5 className="pt-5 pl-4 pl-lg-0">CANDIDATES</h5>
                <ul className="list-inline pt-3 pl-4 pl-lg-0 height-1">
                    <li><a href="/alljobs" className="ftrlnk">Browse Jobs</a></li>
                    <li><a href="/userProfile" className="ftrlnk">Candidate Profile</a></li>
                    <li><a href="/userProfile" className="ftrlnk">Edit Resume</a></li>
                    <li><a href="/alljobs" className="ftrlnk">Saved Jobs</a></li>
                </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <h5 className="pt-5 pl-4 pl-lg-0">OTHER</h5>
                <ul className="list-inline pt-3 pl-4 pl-lg-0 height-1">
                    <li><a href="https://www.resourceintermediaries.org/about-us/" className="ftrlnk">About Us</a></li>
                    <li><a href="https://www.resourceintermediaries.org/category/hr-consulting/" className="ftrlnk">Blog</a></li>
                    <li><a href="/signup" className="ftrlnk">Register</a></li>
                    <li><a href="https://www.resourceintermediaries.org/contact-us/" className="ftrlnk">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5 className="pt-5 pl-4 pl-lg-0">CONTACT</h5>
              
  

                <ul className="list-inline pt-3 pl-4 pl-lg-0 height-1">
                    <li>09098201113</li>
                    <li>recruitment@resourceintermediaries.org</li>
                    <li><a href="https://www.facebook.com/resourceintermediarieslimited/" className="ftrlnk">Facebook</a></li>
                    <li><a href="https://twitter.com/resourceint" className="ftrlnk">Twitter</a></li>
                    <li><a href="https://www.instagram.com/resourceintermediaries/" className="ftrlnk">Instagram</a></li>
                </ul>
                
            </div>
        </div>
    </div>
    <div className="container-fluid footer-2 p-3 pt-4">
        <div className="container-fluid">
            <div className="row text-center">
                <div className="col-12">
                    © Copyright {new Date().getFullYear()} RIL
                </div>
               
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Footer
