import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import LogoHeader from '../components/LogoHeader'
import TopBar from '../components/TopBar'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AuthContext } from '../helpers/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
let nf = new Intl.NumberFormat('en-US')

const MyProfile = () => {
     
    let history = useNavigate();
   

    //check if user loggen in 
    useEffect(() => {
      axios
        .get("https://backend.nochsolutions.com.ng/users/auth", {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error) {
            setAuthState({ ...authState, status: false })
            history("/auths")

          } else {
            
            setAuthState({
              email: response.data.email,
              id: response.data.id,
              first_name:response.data.first_name,
              status: true,
            });
           
          }
        });
    }, []);
    const [authState, setAuthState] = useState({
        email: "",
        id: 0,
        first_name: "",
        status: false,
      });

  

  const [profile, setProfile] = useState([])
  const [edu, setEdu] = useState([])
  const [cv, setCv] = useState([])
  const [exp, setExp] = useState([])

  useEffect(() => {
    const id = authState.id
  const fetchProfile = () => {
    axios.get(`https://backend.nochsolutions.com.ng/users/getProfile/${id}`).then((resProfile) => {
      setProfile(resProfile.data[0])
    })
  }
 
  

  const fetchEdu = () => {
    axios.get(`https://backend.nochsolutions.com.ng/users/getEducation/${id}`).then((resEdu) => {
      setEdu(resEdu.data)
    })
  }

  const fetchCV = () => {
    axios.get(`https://backend.nochsolutions.com.ng/users/getCV/${id}`).then((resCv) => {
      setCv(resCv.data[0])
    })
  }

  const fetchExp = () => {
    axios.get(`https://backend.nochsolutions.com.ng/users/getExperience/${id}`).then((resExp) => {
      setExp(resExp.data)
    })
  }

 
  //fetch jobs
 
    
    fetchExp()
    fetchProfile()
    fetchEdu()
    fetchCV()
   
  }, [])

 

  return (
    <div>
    <TopBar /> 
        <LogoHeader />
        <div className="body container px-3 mt-5">
          <div className='row'>
            <div className='col-lg-12 col-sm-12 col-md-12'>
              <div className='card mb-4'>
                <div className='card-header'>
                  <strong>Personal Profile </strong>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Name: </h4>
                      {profile.first_name} {profile.last_name}
                    </div>
                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Email </h4>
                      {profile.email}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Date of Birth: </h4>
                      {profile.dob}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Gender: </h4>
                      {profile.sex}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Phone: </h4>
                      {profile.alt_phone}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Alternate Phone: </h4>
                      {profile.phone}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Nationality: </h4>
                      {profile.nationality}
                    </div>
                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Address: </h4>
                      {profile.street_address}
                    </div>
                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">State of origin: </h4>
                      {profile.state}
                    </div>

                    <div className='col-md-4'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">Expected Annual Salary </h4>
                      {nf.format(profile.expected_annual_salary)}
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-4'>
                <div className='card-header'>
                  <strong>Education <span className='float-end'><Link to={'/profile'}>Add More</Link></span></strong>
                </div>
                <div className='card-body'>
                  {edu.map((items) => (
                    
                      <div className='row mb-3' key={items.id}>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Institution: </h4>
                          {items.institution}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Course: </h4>
                          {items.course}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Date Entered: </h4>
                          {items.year_entered}
                        </div>

                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Date Graduated: </h4>
                          {items.year_of_completion}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Grade: </h4>
                          {items.grade}
                        </div>
                        <hr style={{ height:'1px', width:'100%', borderWidth:'0', color:'red', backgroundColor:'#000000' }}></hr>
                      </div>
                     
                    
                  ))}
                </div>
              </div>

              <div className='card mb-4'>
                <div className='card-header'>
                  <strong>Experience <span className='float-end'><Link to={'/profile'}>Add More</Link></span></strong>
                </div>
                <div className='card-body'>
                  {exp.map((exps) => (
                    
                      <div className='row mb-3' key={exps.id}>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Comapny Name </h4>
                          {exps.company}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Job Title: </h4>
                          {exps.job_title}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Job Description: </h4>
                          {exps.job_descrip}
                        </div>

                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">Start Date : </h4>
                          {exps.start_date}
                        </div>
                        <div className='col-md-4 col-ms-12 col-lg-4'
                          style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                          
                        >
                          <h4 className="text-muted">End Date or Currently Working </h4>
                          {exps.working === 'true' ? <p>Currently Working</p> : exps.end_date}
                        </div>
                        <hr style={{ height:'1px', width:'100%', borderWidth:'0', color:'red', backgroundColor:'#000000' }}></hr>
                      </div>
                      
                   
                  ))}
                </div>
              </div>
              <div className='card mb-4'>
                <div className='card-header'>
                  <strong>CV <span className='float-end'><Link to={'/profile'}>Update CV</Link></span></strong>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-4 pt-2'
                      style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                      
                    >
                      <h4 className="text-muted">CV </h4>

                      <button className="btn btn-success" >
                        Download CV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
 
  )
}

export default MyProfile
