import React, { useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import Header from './Header'
import Footer from './Footer'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { forgetPassword } from '../feature/authSlice'
import { toast } from 'react-toastify'


const ForgotPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const handlePasswordReset = ()=>{
        
             let data ={
            email
             }
        
        dispatch(forgetPassword(data)).unwrap().then((response)=>{
            toast.success('please check your mail for further instructions')
        }) .catch((error) => {
            toast.error(error);
          });
       
        
    }
  return (
    <>
    <Header />
    <Container className='mt-5 p-5'>
    <div className='col-md-5 mx-auto '>
        
        <Card className='p-3 shadow border-0 bg-white rounded'>
            <Card.Body>
                <div className='mb-3'>
                <h3 className='lead fw-bold text-danger'>Reset Password </h3>
            <small className='text-secondary'>Enter the email associated to your account</small>
            </div>
        <Form>
            <Form.Group className='mb-3'>
                <Form.Label className='text-secondary'>Email</Form.Label>
                <Form.Control type='email' placeholder='Email Address' onChange={(e)=>{setEmail(e.target.value)}} />
            </Form.Group>
            


            <Button variant='danger' className='w-100 btn-lg mt-2' onClick={handlePasswordReset} >
                Reset Password
            </Button>
           
        </Form>
        <div id='signInDiv'></div>

       
          
            

           
        </Card.Body>
    </Card>

    </div>

</Container>
<Footer />
</>
  )
}

export default ForgotPassword