import React, { useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useDispatch } from 'react-redux'
import { addUser } from '../feature/authSlice'
import { toast } from 'react-toastify'
import Spinner1 from '../components/Spinner1'
import { useNavigate } from 'react-router-dom'

const Register = () => {
    const[firstName, setFirstName] = useState('')
    const[lastName, setLastName] = useState('')
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const[confirm, setConfirm] = useState('')
    const[loading, setLoading] = useState(false); 

    const dispatch = useDispatch()
    const navigate = useNavigate()

    if(loading)
    return <Spinner1 />
    


const registerBtn = (e) => {
  e.preventDefault();
  if(password != confirm){
    return toast.error('Password doesnot match')
  }
  setLoading(true); // Set loading to true when the button is clicked
  let data = {
    firstName,
    lastName,
    email,
    password,
  };
  dispatch(addUser(data))
    .unwrap()
    .then(() => {
      navigate("/login");
      toast.success("Please check your email and verify");
      setLoading(false); // Set loading to false after the promise is resolved
    })
    .catch((error) => {
      toast.error(error.message);
      setLoading(false); // Set loading to false after the promise is rejected
    });
};

  

  return (
    <>
    <Header />
    <Container className='mt-5 p-5'>
        <div className='col-md-5 mx-auto '>
            
            <Card className='p-3 shadow border-0 bg-white rounded'>
                <Card.Body>
                    <div className='mb-3'>
                <h3 className='lead fw-bold text-muted'>Create an account </h3>
                
                </div>
            <Form>
            <Form.Group className='mb-3'>
                    <Form.Label className='text-secondary'>First Name</Form.Label>
                    <Form.Control type='text' placeholder='First Name' onChange={(e)=>setFirstName(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='text-secondary'>Last Name</Form.Label>
                    <Form.Control type='text' placeholder='Last Name' onChange={(e)=>setLastName(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='text-secondary'>Email</Form.Label>
                    <Form.Control type='email' placeholder='Email Address' onChange={(e)=>setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                <Form.Label className='text-secondary'>Password</Form.Label>
                    <Form.Control type='password' placeholder='Password' onChange={(e)=>setPassword(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                <Form.Label className='text-secondary'>Confirm Password</Form.Label>
                    <Form.Control type='password' placeholder='Password' onChange={(e)=>setConfirm(e.target.value)} />
                </Form.Group>

                <Button variant='danger' className='w-100 btn-lg mt-2' onClick={registerBtn}>
                    Sign Up 
                </Button>
            </Form>
            </Card.Body>
        </Card>

        </div>

    </Container>

    <Footer />
    
    </>
  )
}

export default Register