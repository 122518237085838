import React from 'react'

const TopBar = () => {
  return (
    <section className='bg-light'>
    <div className='container pt-2 pb-2'>
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-12'>
        <i className="fa-solid fa-square-phone"></i> +234(0)9098201113
        </div>

        <div className='col-lg-4 col-md-4 col-sm-12'>
          <i className='fa-solid fa-envelope'></i> enquiries@resourceintermediaries.org
        </div>

        <div className='col-lg-2 col-md-2 col-sm-12'>
        
        </div>

        <div className='col-lg-3 col-md-3 col-sm-3'>
          <div className='row'>
          <div className='col-lg-3 col-md-3 col-sm-3'>
        <i className='fa-brands fa-facebook-f'></i>
        </div> 
        <div className='col-lg-3 col-md-3 col-sm-3'>
        <i className='fa-brands fa-twitter'></i> 
        </div>
        <div className='col-lg-3 col-md-3 col-sm-3'>
        <i className='fa-brands fa-linkedin'></i> 
        </div>
        <div className='col-lg-3 col-md-3 col-sm-3'>
        <i className='fa-brands fa-google'></i>
        </div>
        </div>

        </div>

        </div>

        
    </div>
    </section>
  )
}

export default TopBar