import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';
import { Provider } from 'react-redux';
import { store } from './app/store';
const Login = process.env.REACT_APP_GOOGLE_CLIENT_ID;




ReactDOM.render( 
<BrowserRouter>
  <GoogleOAuthProvider clientId={Login}>
  <React.StrictMode>
  <Provider store={store}> 
    <App />
    </Provider>
  </React.StrictMode>
  </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

