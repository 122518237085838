import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { getJobs, setPage } from '../feature/webSlice'
import Spinner from '../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
let nf = new Intl.NumberFormat('en-US')

const AllJobs = () => {
  const { jobs, page } = useSelector((state) => state.web)

  const dispatch = useDispatch()


  const [currentPage, setCurrentPage] = useState(1);
  
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  };

  useEffect(() => {
    async function fetchData() {
      await dispatch(getJobs({page:currentPage}))
    }
    fetchData()
  }, [dispatch, page, currentPage]);

  const onPageChange = () => {
    dispatch(getJobs())
  }

  if (!jobs)
    return (
      <Spinner />
    )


  return (
    <>
      <Header />
      <div className='container mt-5' style={{minHeight:'calc(50vh)'}}>
        <div className='row'>
          <div className='col-md-12 col-lg-12 col-sm-12'>
            <h4 className='text-muted'>All Jobs</h4>
            <hr style={{ height: '1px', width: '100%', borderWidth: '0', color: 'red', backgroundColor: '#000000' }}></hr>

            <div>

              {jobs.data.rows.map(item =>


                <><div className="card p-3 mb-2" key={item.id}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <h4 style={{ fontWeight: "bold" }}>{item.jobTitle} </h4>
                      <p><i className="fa-solid fa-earth-africa"></i> {item.jobLocation} </p>

                    </div>

                    <div className='col-md-3'>
                      {item.salaryFrom !== '' ? <p><i className="fa-solid fa-money-check-dollar text-secondary"></i> NGN {nf.format(item.salaryFrom)} </p> : ''}

                      {item.jobType !== '' ? <p><i className="fa-solid fa-clock"></i> {item.jobType}</p> : ''}
                    </div>

                    <div className='col-md-3 mt-4'>
                      <Link to={`/singleJob?id=${item.id}`}><button className='btn btn-outline-danger ml-auto' style={{ width: "100%" }}>View More</button></Link>
                    </div>
                  </div>
                </div>
                <div className='mt-5'>
                  
                  </div>
                </>
              )}
<ReactPaginate
                    pageCount={jobs.totalPages}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageLinkClassName={'page-link'}
                   
                  />
            </div>
            <nav>

            </nav>
          </div>

        </div>
      </div>
      <Footer />

    </>
  )
}

export default AllJobs