import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../feature/authSlice'


const JobHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutUser = () =>{
  dispatch(logout())
  navigate('/')
  }
 
// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'))

  return (
    <div className='jobNav'>
   <nav className="navbar navbar-expand-lg navbar-light bg-white shadow p-3 mb-5 bg-body rounded">
  <div className="container-fluid">
    <a className="navbar-brand" href="./"><img src='../images/logo.png' width={60} /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item pr-2">
          <a className="nav-link active" aria-current="page" href="/">Search Jobs</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'/feedback'}>Feedbacks</a>
        </li>
  
      </ul>
      <div className="d-flex">
      <ul className="navbar-nav  mb-2 mb-lg-0">
      <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {user ? user.firstName : ''}
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a className="dropdown-item" href={'/UserProfile'}>Profile</a></li>
            <li><a className="dropdown-item" onClick={logoutUser}>Logout</a></li>
          </ul>
        </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
 </div>
  )
}

export default JobHeader