import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../feature/authSlice'
import { toast } from 'react-toastify'

const ChangePassword = () => {
    const [password, setPassword] = useState('')
    const [confrim, setConfirmPassword] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    
    let params = (new URL(document.location)).searchParams;
    let emailToken = params.get('t')
    let email = params.get('u')

    const handlePasswordChange = () =>{
        try {
            if(password !== confrim){
                return toast.error("Password doesnot match")
            }
            let data ={
                password,
                emailToken,
                email
            }
            dispatch(changePassword(data)).unwrap().then((response)=>{
                navigate('/')
                toast.success('Password changed successfully')
            })
        } catch (error) {
            toast.error(error)
        }
    }
  return (
    <>
      <Header />
    <Container className='mt-5 p-5'>
        <div className='col-md-5 mx-auto '>
            
            <Card className='p-3 shadow border-0 bg-white rounded'>
                <Card.Body>
                    <div className='mb-3'>
                <h3 className='lead fw-bold text-muted'>Change Password</h3>
                <small className='text-secondary'>Enter your new password</small>
                </div>
            <Form>
                <Form.Group className='mb-3'>
                    <Form.Label className='text-secondary'>New Password</Form.Label>
                    <Form.Control type='password' placeholder='New Password' onChange={(e)=>{setPassword(e.target.value)}} />
                </Form.Group>

                <Form.Group className='mb-3'>
                <Form.Label className='text-secondary'>Confirm Password</Form.Label>
                    <Form.Control type='password' placeholder='Password'  onChange={(e)=>{setConfirmPassword(e.target.value)}} />
                </Form.Group>
                


                <Button variant='danger' className='w-100 btn-lg mt-2' onClick={handlePasswordChange}>
                    Change Password
                </Button>
               
            </Form>           
               
            </Card.Body>
        </Card>

        </div>

    </Container>
    <Footer />

    </>
  )
}

export default ChangePassword