import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

// Get user from localstorage
const users = JSON.parse(localStorage.getItem('user'))



//get main link
const url = process.env.REACT_APP_BACKEND



const initialState = {
  userProfile: null,
  experience: null,
  education: null,
  skill: null,
  certificate: null,
  titles:null,
  singleWork:null,
  singleEdu:null,
  singleCert:null,
  applied:null
}

//delete skill
export const deleteSkill = createAsyncThunk(
  'users/deleteSkill',
  async(ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.delete(`${url}/users/deleteSkill/${ids}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//get all appliedjob
export const appliedjob = createAsyncThunk(
  'users/appliedJob',
  async(_, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/appliedJob/${id}`, config)
      return response.data 
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)
//updateCertification
export const updateCerts = createAsyncThunk(
  'users/updateCerts',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
     const response =  await axios.post(`${url}/users/updateCertification/`, data, config)
      return  response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });
//updateEducation
export const updateWorks = createAsyncThunk(
  'users/updateWorks',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
     const response =  await axios.post(`${url}/users/updateExperience/`, data, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });
//updateEducation
export const updateEdus = createAsyncThunk(
  'users/updateEdus',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
     const response =  await axios.post(`${url}/users/updateEducation/`, data, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//get single Education
export const singleEdus = createAsyncThunk(
  'users/singleEdus',
  async (ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
     const response =  await axios.get(`${url}/users/getSingleEducation/${ids}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });
//get single work experience
export const singleWorks = createAsyncThunk(
  'users/singleWorks',
  async (ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response =  await axios.get(`${url}/users/getSingleExperience/${ids}`,  config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

  //get single certification
export const singleCerts = createAsyncThunk(
  'users/singleCerts',
  async (ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response =  await axios.get(`${url}/users/getSingleCerts/${ids}`,  config)
      return await response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });


//add Title
export const addTitle = createAsyncThunk(
  'users/addTitle',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return await axios.post(`${url}/users/addAbout`, data, config)

    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//get Tile and about
export const getTitle = createAsyncThunk(
  'users/getTitle',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getAbout/${id}`, config)
      return response.data[0]
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });



//get Certification
export const getCertification = createAsyncThunk(
  'users/getCertification',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getCerts/${id}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });



//add certifications
export const addCertification = createAsyncThunk(
  'users/addCertification',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return await axios.post(`${url}/users/addCertification`, data, config)

    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });


//get Education
export const getSkills = createAsyncThunk(
  'users/getSkills',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getSkills/${id}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });


//add skills
export const addSkills = createAsyncThunk(
  'users/addWork',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return await axios.post(`${url}/users/addSkills`, data, config)

    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });


//add work experience
export const addWork = createAsyncThunk(
  'users/addWork',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return await axios.post(`${url}/users/addExperience`, data, config)

    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//get Education
export const getEducation = createAsyncThunk(
  'users/getEducation',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getEducation/${id}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//add Eductaion
export const addEducation = createAsyncThunk(
  'users/addEduction',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return await axios.post(`${url}/users/addEducation`, data, config)

    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });



//get all experience
export const getWorks = createAsyncThunk(
  'users/works',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id


      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getExperience/${id}`, config)
      return response.data.rows
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//get user profile
export const getUserProfile = createAsyncThunk(
  'users/userprofile',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const id = thunkAPI.getState().auth.user.id
      const emails = thunkAPI.getState().auth.user.email

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getProfile/${emails}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }

  });

//update profile
export const createProfile = createAsyncThunk(
  'users/addProfile',
  async (data, thunkAPI) => {

    try {
      // const token = thunkAPI.getState().auth.user.token
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   }, 
      // }
      const response = await axios.post(`${url}/users/addProfile`, data)
      return response.data


    } catch (error) {
      return thunkAPI.rejectWithValue(error)

    }
  }
)





export const profileSlice = createSlice({
  name: 'profile',
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    (builder)

      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getWorks.fulfilled, (state, action) => {
        state.experience = action.payload
      })
      .addCase(getEducation.fulfilled, (state, action) => {
        state.education = action.payload
      })
      .addCase(getSkills.fulfilled, (state, action) => {
        state.skill = action.payload
      })
      .addCase(getCertification.fulfilled, (state, action) => {
        state.certificate = action.payload
      })
      .addCase(getTitle.fulfilled, (state, action) => {
        state.titles = action.payload
      })
      .addCase(singleCerts.fulfilled, (state, action)=>{
        state.singleCert = action.payload
      })
      .addCase(singleEdus.fulfilled, (state, action)=>{
        state.singleEdu = action.payload
      })
      .addCase(singleWorks.fulfilled, (state, action)=>{
        state.singleWork = action.payload
      })
      .addCase(appliedjob.fulfilled, (state, action)=>{
        state.applied = action.payload
      })





  }
})

export default profileSlice.reducer