import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Logo from './Logo'
import Logo1 from './Logo1'
import { useSelector } from 'react-redux'

const LogoHeader = () => {
const {user} = useSelector((state)=>state.auth)    
   
    
  return (
    <>
    <Logo /> 

    </>
  )
}

export default LogoHeader