import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar'
import { toast } from 'react-toastify'
import LogoHeader from './LogoHeader';
import Footer from './Footer';
import { getSingleJob } from '../feature/webSlice';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { applyByProfile, applyByCv, checkJob } from '../feature/webSlice';
import axios from 'axios';
import Spinner1 from './Spinner1';



let nf = new Intl.NumberFormat('en-US')





const SingleJob = () => {
  //get main link
const url = process.env.REACT_APP_BACKEND

  const { singleJob } = useSelector((state) => state.web)
  const { user } = useSelector((state) => state.auth)

  let history = useNavigate()

  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("id")

  const dispatch = useDispatch()

  const {jobCheck} = useSelector((state)=>state.web)


  //show or hide 
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  //get select Error
  const [selectError, setError] = useState('')

  const [applyType, setProfile] = useState('')
  const [coverLetter, setCover] = useState('')
  const [cv, setCv] = useState('')
  const [userId, setUserId] = useState('')
  const userIds = user ? user.id : ''



  const application =  (e) => {
    e.preventDefault()
    if (applyType === 'withProfile') {
      setLoading(true)
      let data = {
        coverLetter:coverLetter,
        jobId:id,
        userId:user.id,
        applyType:applyType
      }
      dispatch(applyByProfile(data)).unwrap().then(() => {
        setLoading(false)
        toast.success('Application sent successfull')
        history('/')
      }).catch(toast.error)
      {
        setLoading(false)
      }
    } else {
      setLoading(true)
      let data = new FormData()
      data.append('coverLetter', coverLetter)
      data.append('jobId', id)
      data.append('userId', user.id)
      data.append('applyType', applyType)
      data.append('cv', cv)
      dispatch(applyByCv(data)).unwrap().then(()=>{
        setLoading(false)
        toast.success('Application sent successfull')
        history('/')
      }).catch(toast.error)
      {
        setLoading(false)
      }
      
  }
  }
  useEffect(() => {
    dispatch(getSingleJob(id)).unwrap().catch(toast.error)
    let data ={
      userId:userIds,
      jobId:id
    }
    dispatch(checkJob(data))
  }, [dispatch, id]);


  //to show html added to desc
  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  if(loading){
    return<Spinner1 />
  }

  if (!singleJob) {
    return <Spinner />
  }

  console.log(jobCheck)

  return (

    <><TopBar />
      <LogoHeader />

      <div className='container mt-5'>

        <div className='row'>
          <div className="col-md-7" key={singleJob.id}>
            <div className="card p-3 mb-2">
              <div className='row'>
                <div className='col-md-12'>
                  <h4 style={{ fontWeight: "bold" }}>{singleJob.jobTitle}</h4>
                </div>

                <div className='col-md-12'>
                  <h5 className='text-secondary' style={{ fontWeight: "bold" }}>{singleJob.company}</h5>
                </div>
                <div className='col-md-12 mt-3'>
                  <p><i className="fa-solid fa-earth-africa"></i> {singleJob.jobLocation} </p>
                </div>

                <div className='col-md-12 mt-3'>
                  <h3>Job Description</h3>
                  <div> <UnsafeComponent html={singleJob.jobDescription} />  </div>
                </div>

                <div className='col-md-12 mt-3'>
                  {singleJob.job_type !== '' ? <p><i className="fa-solid fa-clock"></i> {singleJob.jobType}</p> : ''}
                </div>

                <div className='col-md-12 mt-3'>
                  {singleJob.salary_from !== '' ? <p><i className="fa-solid fa-money-check-dollar text-secondary"></i> NGN {nf.format(singleJob.salaryFrom)} - {nf.format(singleJob.salaryTo)} </p> : ''}

                </div>


                <div className='col-md-12 mt-3'>
                  {singleJob.experience !== '' ? <p>Experience:  {singleJob.experience} Years </p> : ''}

                </div>


              </div>
            </div>
          </div>

          <div className='col-md-5 col-lg-5 col-sm-12'>
            <h4 className='text-muted'>Apply Here</h4>
            <hr style={{ height: '1px', width: '100%', borderWidth: '0', color: 'red', backgroundColor: '#000000' }}></hr>


            <div>


              {user && jobCheck === 'not applied' ? 

                <div className="card p-3 mb-2"  >
                  <div className='row'>

                    <div className='col-md-12 col-sm-12 col-lg-12 mb-3'>
                      <label className='mb-1'>Cover Letter</label>
                      <textarea className='form-control' onChange={(e) => setCover(e.target.value)} placeholder='Cover letter' defaultValue='None' name='cover_letter'></textarea>
                    </div>

                   
                    <div className='form-group mb-3'>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="withProfile" onChange={(e)=>setProfile(e.target.value)} />
                      <label className="form-check-label">
                        Apply with Profile
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="withCv" onChange={(e)=>setProfile(e.target.value)} />
                      <label className="form-check-label" >
                        Apply with CV
                      </label>
                    </div>
                    </div>
                   
                      <div className='col-md-12 col-sm-12 col-lg-12 mb-3'>
                        <label className='mb-1'>Upload CV</label>
                        <input type='file' accept='.pdf' name='cv' onChange={(e) => setCv(e.target.files[0])} className='form-control' />
                        <small>.pdf 2mb</small>
                      </div>
      

                  </div>
                  <div className='col-md-12 col-sm-12 col-lg-12 mb-3'>
                    <input type='submit' className='btn btn-danger btn-block w-100' onClick={application} value='Apply Now' name='btnadd' />
                  </div>

                </div>
                : 
                user && jobCheck =='applied' ?
                <h5>You already applied for this job</h5>
                :
                <div className='card p-3 mb-2'>
                  <h3 className='fs-5 fw-bold lead mb-5'>Sign up or Login to apply</h3>

                  <a href='/login'><button className='btn w-75 btn-lg btn-danger mb-4 btn-block'>Login Here </button></a>
                  <a href='/signup'><button className='btn w-75 btn-lg  btn-danger mb-4 btn-block'>Sign Up </button></a>

                </div>
              }




            </div>

          </div>

        </div>

      </div>
      <Footer />
    </>
  )
}

export default SingleJob