import React, { useEffect, useState } from 'react'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getWorks, addWork } from '../../feature/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Experience = ({ profiles }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { experience } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)
    const [hasExecuted, setExecuted] = useState(false)

    const dispatch = useDispatch()

    //getallWorks
    useEffect(() => {
        async function fetchData() {
            await dispatch(getWorks())
        }
        fetchData()
    }, [dispatch, hasExecuted])






    //states
    const [workTitle, setWorkTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [workDescription, setDescrip] = useState('')
    const [company, setCompany] = useState('')
    const [currentlyWorking, setWorking] = useState('')
    const userId = user.id


    //add experiences
    const addExperience = (e) => {
        e.preventDefault()
        let data = {
            workTitle,
            workDescription,
            startDate,
            endDate,
            company,
            currentlyWorking,
            userId

        }
        dispatch(addWork(data)).unwrap().then((response) => {
            setExecuted(!hasExecuted)
            window.scrollTo(0, 0); // scroll to the top of the page 
            handleClose()
        })
    }

    function UnsafeComponent({ html }) {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
      }

    return (
        <>
            <div className='mt-3'>
                <h4 className='fw-bold text-muted'>
                    Work Experience{' '}
                    <i
                        className='fa fa-plus text-danger mx-2 my-2'
                        style={{ cursor: 'pointer' }}
                        onClick={handleShow}
                    ></i>
                    <a href='/experience'>
                        <i className='fa fa-edit text-danger mx-2 my-2'></i>
                    </a>
                </h4>
                <div className='mt-4'>
                {!experience ? (
                    'loading...'
                ) : experience.length === 0 ? (
                    <div>No job history added</div>
                ) : (
                    experience.map((items) => (
                        <div className="d-flex mb-4" key={items.id}>
                            <div className="flex-shrink-0">
                                <div className='big-circle1'>
                                    <i className='fa fa-building-o'></i>
                                </div>
                            </div>
                            <div className="flex-grow ms-3">
                                <div className="flex-column  mb-0">
                                    <h4 className='fw-bold text-danger m-0'>{items.company}</h4>
                                </div>
                                <div className="mt-2">
                                <div> <UnsafeComponent html={items.workDescription} />  </div>
                                    <small className='text-muted'>
                                        {items.workTitle}{' '}
                                        <span className='bg-light text-danger'>
                                            {moment(items.startDate).format('LL')} to{' '}
                                            {items.currentlyWorking === 'Yes' && (!items.endDate || items.endDate === null)
                                                ? ' Currently working'
                                                : moment(items.endDate).format('LL')}
                                        </span>{' '}
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                </div>
            </div>
            
            <div className="hr border border-muted"></div><Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Work Experiences</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className='container'>
                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Title</label>
                                <input type='text' placeholder='Work Title' onChange={(e) => setWorkTitle(e.target.value)} className='form-control' required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Job Description</label>
                                <ReactQuill theme="snow" value={workDescription} onChange={setDescrip} />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Company Name</label>
                                <input type='text' placeholder='Company Name' onChange={(e) => setCompany(e.target.value)} className='form-control' required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Start Date</label>
                                <input type='date' placeholder='Start date' className='form-control' onChange={(e) => setStartDate(e.target.value)} required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>End Date</label>
                                <input type='date' placeholder='End date' className='form-control' onChange={(e) => setEndDate(e.target.value)} required />
                            </div>

                            <div className='form-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" value="Yes" onChange={(e) => setWorking(e.target.value)} />
                                    <label className="form-check-label">
                                        Currently Working here
                                    </label>
                                </div>
                            </div>



                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type='submit' variant="primary" onClick={addExperience}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal></>

    )
}

export default Experience