import React from 'react'
import Logo from './Logo'
import TopBar from './TopBar'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import LogoHeader from './LogoHeader'
import { useDispatch, useSelector } from 'react-redux'
import { jobSearch } from '../feature/webSlice'
import Spinner from './Spinner'
let nf = new Intl.NumberFormat('en-US')


const Job = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const keywords = queryParams.get("keyword") 
  const locations = queryParams.get("location") 

 const datas = {
  keywords,
  locations
 }

  const { search } = useSelector((state) => state.web)

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(jobSearch(datas))

  }, [dispatch]);



  if(!search)
  return(<Spinner />)



  return (
    <>
      <TopBar />
      <LogoHeader />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card mb-3'>
              <div className='card-body'>
                <h4 className='mb-5'>Total Job found <span className='text-danger'>{search.data.count}</span></h4>
              </div>
            </div>




            <div>

              {search.data.rows.map(item =>

                <div className="col-md-12" key={item.id}>
                  <div className="card p-3 mb-2">
                    <div className='row'>
                      <div className='col-md-6'>
                        <h4 style={{ fontWeight: "bold" }}>{item.jobTitle}</h4>
                        <p><i className="fa-solid fa-earth-africa"></i> {item.jobLocation} </p>

                      </div>

                      <div className='col-md-3'>
                        {item.salaryFrom !== '' ? <p><i className="fa-solid fa-money-check-dollar text-secondary"></i> NGN {nf.format(item.salaryFrom)} </p> : ''}

                        {item.jobType !== '' ? <p><i className="fa-solid fa-clock"></i> {item.jobType}</p> : ''}
                      </div>

                      <div className='col-md-3 mt-4'>
                        <Link to={`/singleJob?id=${item.id}`}><button className='btn btn-outline-danger ml-auto' style={{ width: "100%" }} >View More</button></Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
         </div>
          </div>
        </div>
      </div>




    </>
  )

}

export default Job