import React, { useEffect, useState } from 'react'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getCertification, updateCerts, singleCerts } from '../../feature/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import JobHeader from './JobHeader'
import Footer from '../Footer'
import Spinner1 from '../Spinner1'

const Certification = () => {
    const { user } = useSelector((state) => state.auth)
    const {certificate, singleCert } = useSelector((state)=>state.profile)
    
    //dispatch
    const dispatch = useDispatch()

    //certification state
    const[certName, setCertName] = useState('')
    const[provider, setProvider] = useState('')
    const[certNumber, setNumber] = useState('')
    const[getDate, setDate] = useState('')

    //handle modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = async (ids) => {
        try {
            const result = await dispatch(singleCerts(ids)).unwrap();
            setCertName(result[0].certName);
            setProvider(result[0].provider);
            setNumber(result[0].certNumber);
            setDate(result[0].getDate);
            setShow(true); // Set showModal to true to show the modal
          } catch (error) {
            console.error('Error fetching data:', error);
          }
      };
        

    //getpayload
    

   
   

 

    const submitCert = (ids) =>{
      
        let data ={
            certName,
            provider,
            certNumber,
            getDate,
            id:ids
        }
        dispatch(updateCerts(data)).unwrap().then(()=>{
            toast.success('Certification added')
            handleClose()
        }).catch(toast.error)

    }

    useEffect(async()=>{
        dispatch(getCertification())
    },[])


  return (
    <>
    <JobHeader />
           
           <div className='container'>
               <a href='/userProfile' className='lead mb-4 text-danger'><i className='fa fa-arrow-left'></i> back to profile</a>
               <Row className='mt-5'>
                   <Col md={8} sm={12}>
                       <h3 className='display-6 mb-4 text-muted'>Cerification</h3>
    <Card className='job-card mb-4 border-0'>
        
        <div className='card-body'>
        <Row>
                
                   
                    
                {certificate == undefined ? <div>No certification history added</div> ? !certificate : 'loading...'   : certificate.map(items =>(
                <> 
                <Col md={12} sm={12} className='mb-2'>
                    <div key={items.id}>
                    <div className='d-flex float-end'>
                                                    <button className='btn btn-sm btn-outline-danger' onClick={() => handleShow(items.id)}> <i className='fa fa-edit'></i></button>
                                                </div>
                        <p className='lead fw-bold m-0'>{items.certName}</p>
                        <small className='text-muted'>{items.provider}<span className='bg-light text-danger'> {moment(items.getDate).format('LL')}</span></small>
                        <p><small className='text-muted'>{items.certNumber}</small> </p> 
                    </div>
                    </Col>   
                </>
                 
                
             
                ))}
                

                
               
                
            </Row>
       
        </div>
    </Card>

      {/* Add personal profile */}
      <>
      <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Certifications</Modal.Title>
                </Modal.Header>
                <Form>
                <Modal.Body>
                {singleCert === null ? <Spinner1 />
                :
                <div className='container'>
                                      <div className='form-group mb-3'>
                                          <label className='form-label lead fs-6 text-danger'>Certification Name</label>
                                          <input type='text' placeholder='Certification Name' className='form-control' value={certName} required onChange={(e) => setCertName(e.target.value)} />
                                      </div>

                                      <div className='form-group mb-3'>
                                          <label className='form-label lead fs-6 text-danger'>Provider</label>
                                          <input type='text' placeholder='Provider' value={provider} className='form-control' required onChange={(e) => setProvider(e.target.value)} />
                                      </div>

                                      <div className='form-group mb-3'>
                                          <label className='form-label lead fs-6 text-danger'>Date Earned</label>
                                          <input type='date' placeholder='Date Earned' className='form-control' required onChange={(e) => setDate(e.target.value)} value={moment(getDate).format('YYYY-MM-DD')} />
                                      </div>


                                      <div className='form-group mb-3'>
                                          <label className='form-label lead fs-6 text-danger'>Cerification Number</label>
                                          <input type='text' placeholder='Certification Number' className='form-control' value={certNumber} required onChange={(e) => setNumber(e.target.value)} />
                                      </div>


                                  </div>
                }
                                  
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="primary" onClick={()=>submitCert(singleCert[0].id)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
            </>

            </Col>
            </Row>
            </div>
            <Footer />
    </>
  )
}

export default Certification