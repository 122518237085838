import React, { useEffect } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../feature/authSlice'
import { toast } from 'react-toastify'



const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user} = useSelector((state)=>state.auth)
   
    useEffect(()=>{
        if(user){
            navigate('/UserProfile')
        }else{
            navigate('/login')
        }
    },[])
//get main link
const url = process.env.REACT_APP_BACKEND
    //add data to 
  
   //login via button
    const loginBtn = (e)=>{
        e.preventDefault()
        let data = {
            email,
            password
        }

        dispatch(login(data)).unwrap()
           .then((response)=>{
            navigate('/userProfile')
        }).catch((error)=>{
            toast.error(error);
        })

    }

    const Login = process.env.REACT_APP_LINKEDIN_KEY;

  
    //linkedinLogin
    const loginLinkedin = () =>{
        window.location.href = `${url}/auth/linkedAuth`;
    }
    
     //linkedinLogin
     const loginGoogle = () =>{
        window.location.href = `${url}/auth/googleLogin`;
        
    }
    
 

      
  return (
    <>
    <Header />
    <Container className='mt-5 p-5'>
        <div className='col-md-5 mx-auto '>
            
            <Card className='p-3 shadow border-0 bg-white rounded'>
                <Card.Body>
                    <div className='mb-3'>
                <h3 className='lead fw-bold text-muted'>Welcome back </h3>
                <small className='text-secondary'>Enter your credentials to login</small>
                </div>
            <Form>
                <Form.Group className='mb-3'>
                    <Form.Label className='text-secondary'>Email</Form.Label>
                    <Form.Control type='email' placeholder='Email Address' onChange={(e)=>{setEmail(e.target.value)}} />
                </Form.Group>

                <Form.Group className='mb-3'>
                <Form.Label className='text-secondary'>Password</Form.Label>
                    <Form.Control type='password' placeholder='Password'  onChange={(e)=>{setPassword(e.target.value)}} />
                    <div className='float-end mt-2'>
                    <p className='text-danger fw-bold link-danger'><a href='/forgotPassword'>forgot password</a></p>
                </div>
                </Form.Group>
                


                <Button variant='danger' className='w-100 btn-lg mt-2' onClick={loginBtn} >
                    Sign In 
                </Button>
               
            </Form>
            <div id='signInDiv'></div>

            <div className='mt-5'>
                    <img src='/images/btn_google.png' alt='signinwithgoogle' onClick={loginGoogle}   className='btn btn-sm w-50' />
                    <img src='/images/signin-button.png' alt='signinwithgoogle' onClick={loginLinkedin } className='btn btn-sm w-50' />
    
                   
                    
                </div>

              
                

               
            </Card.Body>
        </Card>

        </div>

    </Container>

    <Footer />
    
    </>
  )
}

export default Login