import React, { useEffect, useState } from 'react'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getWorks, singleWorks, updateWorks } from '../../feature/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment  from 'moment/moment'
import Footer from '../Footer'
import JobHeader from './JobHeader'
import Spinner1 from '../Spinner1'


const AllWork = () => {    

    //fetch payloads
const { experience, singleWork } = useSelector((state)=>state.profile)
const {user} = useSelector((state)=>state.auth)

 //states
 const[workTitle, setWorkTitle] = useState('')
 const[startDate, setStartDate] = useState('')
 const[endDate, setEndDate] = useState('')
 const[workDescription, setDescrip] = useState('')
 const[company, setCompany] = useState('')
 const[currentlyWorking, setWorking] = useState('')
    
const dispatch = useDispatch()
 const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = async (ids) => {
        await dispatch(singleWorks(ids)).unwrap().then(()=>{
            setWorkTitle(singleWork[0].workTitle)
            setStartDate(singleWork[0].startDate)
            setEndDate(singleWork[0].endDate)
            setDescrip(singleWork[0].workDescription)
            setCompany(singleWork[0].company)
            setWorking(singleWork[0].currentlyWorking)
            setShow(true);
        })
     

    }




    

    //getallWorks
    useEffect(async ()=>{
        await dispatch(getWorks())
       // console.log(experience.map((user)=>user.id))
    },[dispatch])


   
   
   

    //add experiences
    const addExperience=(ids)=>{
        
        let data = {
            workTitle,
            workDescription,
            startDate,
            endDate,
            company,
            currentlyWorking,
            id:ids

            
        }
        dispatch(updateWorks(data)).unwrap().then(()=>{
            window.location.reload(false)
            handleClose()
        })
    }
    
    return (
        <>
             
            <JobHeader />
           
            <div className='container'>
                <a href='/userProfile' className='lead mb-4 text-danger'><i className='fa fa-arrow-left'></i> back to profile</a>
                <Row className='mt-5'>
                    <Col md={8} sm={12}>
                        <h3 className='display-6 mb-4 text-muted'>Job Experiences</h3>
            <Card className='job-card mb-4 border-0'>
                <div className='card-body'>
                    <Row>
                        {!experience ? 'loading...' : experience.length === 0 ? <div>No job history added</div> : experience.map(items =>(
                            <div key={items.id}>
                            <Col md={12} sm={12} className='mb-2' key={items.id}>
                            <div className='d-flex float-end'>
                                                    <button className='btn btn-sm btn-outline-danger' onClick={() => handleShow(items.id)}> <i className='fa fa-edit'></i></button>
                                                </div>
                                <p className='lead fw-bold m-0'  >{items.company}</p>
                                <small className='text-muted'>{items.workTitle} <span className='bg-light text-danger'>{moment(items.startDate).format('LL')} to {moment(items.endDate).format('LL')}</span> </small>
                            </Col><hr className='border-danger' />
                            </div>


                       ))}
                        
                    </Row>

                </div>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Work Experiences</Modal.Title>
                </Modal.Header>
                
                    <Modal.Body>
                    {!singleWork ? <Spinner1 /> :
                        <div className='container'>
                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Title</label>
                                <input type='text' placeholder='Work Title' onChange={(e)=>setWorkTitle(e.target.value)} value={workTitle}  className='form-control' required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Job Description</label>
                                <textarea className='form-control' onChange={(e)=>setDescrip(e.target.value)} value={workDescription} placeholder='Job Description'></textarea>
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Company Name</label>
                                <input type='text' placeholder='Company Name' onChange={(e)=>setCompany(e.target.value)} value={company} className='form-control' required />
                            </div>

                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>Start Date</label>
                                <input type='date' placeholder='Start date' className='form-control' onChange={(e)=>setStartDate(e.target.value)} value={moment(startDate).format('YYYY-MM-DD')} required />
                            </div>
                            {endDate !== '' ? 
                            <div className='form-group mb-3'>
                                <label className='form-label lead fs-6 text-danger'>End Date</label>
                                <input type='date' placeholder='End date' className='form-control' value={moment(endDate).format('YYYY-MM-DD')} onChange={(e)=>setEndDate(e.target.value)} required />
                            </div>
                                : 
                            <div className='form-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckDefault" value="Yes" checked onChange={(e)=>setWorking(e.target.value)} />
                                    <label className="form-check-label">
                                        Currently Working here
                                    </label>
                                </div>
                            </div>
                        }



                        </div>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type='submit' variant="primary" onClick={()=>addExperience(singleWork[0].id)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                
            </Modal>
            </Col>
            </Row>
            </div>
            <Footer />
        </>
    )
}

export default AllWork