import React, { useState } from 'react'
import LogoHeader from './LogoHeader'
import TopBar from './TopBar'

const Header = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const verifyParam = searchParams.get('verify');
  const [showMessage, setShowMessage] = useState(false);


  return (
    <header className='fixed-top' style={{ position:'relative'}}>
     {verifyParam?
    
     <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
          Your email has been verified successfully
          <i className='fa fa-times mx-3' data-dismiss="alert" aria-label="Close" onClick={() => setShowMessage(false)}></i>
      </div>:''}
        <TopBar />
        <LogoHeader />
        
    </header>
  )
}

export default Header