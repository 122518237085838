import React from 'react'
import TopBar from '../components/TopBar'
import LogoHeader from '../components/LogoHeader'


const Profile = () => {



    // let history = useNavigate();
    // const [authState, setAuthState] = useState({
    //     email: "",
    //     id: 0,
    //     first_name: "",
    //     status: false,
    //   });
   
      // useEffect(() => {
      //   axios
      //     .get("https://backend.nochsolutions.com.ng/users/auth", {
      //       headers: {
      //         accessToken: localStorage.getItem("accessToken"),
      //       },
      //     })
      //     .then((response) => {
      //       if (response.data.error) {
      //         setAuthState({ ...authState, status: false })
      //         history("/auths")

      //       } else {
              
      //         setAuthState({
      //           email: response.data.email,
      //           id: response.data.id,
      //           first_name:response.data.first_name,
      //           status: true,
      //         });
      //         console.log(authState)
      //       }
      //     });
      // }, []);
    
  return (
    <>
 
     <TopBar  />
 <LogoHeader />
 <div className="container mt-5">
     <div className='offset-md-2 col-md-8 col-sm-12 col-lg-8'>
     <div className='row'>
    <div className="card">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item text-center"> <a className="nav-link active btl" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Profile</a> </li>
            <li className="nav-item text-center"> <a className="nav-link btr" id="pills-cv-tab" data-toggle="pill" href="#pills-cv" role="tab" aria-controls="pills-cv" aria-selected="false">Upload CV</a> </li>
            <li className="nav-item text-center"> <a className="nav-link btr" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="true">Job Experience</a> </li>
            <li className="nav-item text-center"> <a className="nav-link btr" id="pills-edu-tab" data-toggle="pill" href="#pills-edu" role="tab" aria-controls="pills-edu" aria-selected="true">Education</a> </li>
        </ul>
        {/* <div className="tab-content" id="pills-tabContent">
          <TabProfile fname={authState.first_name} userid={authState.id} />
           <TabCv userid={authState.id}/>
           <TabExp userid={authState.id} />
           <TabEdu userid={authState.id}/>
        </div> */}
    </div>
</div>
</div>
</div>
</>
  
  )
}

export default Profile