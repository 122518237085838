import React from 'react'
import { useState, useEffect } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { createProfile, getUserProfile } from '../../feature/profileSlice'
import moment from 'moment';
import { toast } from 'react-toastify';


const PersonalInfo = () => {
    //get payload
    const { userProfile } = useSelector((state) => state.profile)

    //set Loader Color
    const[color, setColor] = useState('#FF0000')


    //initailize dispatch
    const dispatch = useDispatch()

    
    const[hasExecuted, setExecuted] = useState(false)


 //fetching item
 useEffect( () => {
    async function getProfiles(){
        await dispatch(getUserProfile())
    }
   getProfiles()
}, [dispatch, hasExecuted])

    const users = !userProfile ? '': userProfile[0]
    
    //state for form  
    const email = users.email
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [nationality, setNationality] = useState('')
    const [state, setState] = useState('')
    const [phone, setPhone] = useState('')
    const [altPhone, setAltPhone] = useState('')
    const [city, setCity] = useState('')
    const [gender, setGender] = useState('')
    const [expectedPay, setExpectedPay] = useState('')
    const [industry, setIndustry] = useState('')
   


    //handle modal view 
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
        setFirstName(users.firstName)
        setLastName(users.lastName)
        setDob(users.dob)
        setNationality(users.nationality)
        setState(users.state)
        setPhone(users.phone)
        setAltPhone(users.altphone)
        setCity(users.city)
        setGender(users.gender)
        setExpectedPay(users.expectedPay)
        setIndustry(users.industry)
    }

       


    //submit items  
    const onSubmit = (e) => {
        e.preventDefault()

        let data = {
            firstName,
            lastName,
            email,
            dob,
            nationality,
            state,
            altPhone,
            city,
            gender,
            expectedPay,
            phone,
            industry
        }

        dispatch(createProfile(data)).then((response) => {
            setExecuted(!hasExecuted)
            window.scrollTo(0, 0); // scroll to the top of the page
            handleClose()
            toast.success('Profile Updated')
        })

    }

 // Format the currency using Intl.NumberFormat
 const formattedExpectedPay = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN', // Replace 'USD' with the appropriate currency code if needed
  }).format(users.expectedPay);
    

    return (
        <>
             <div className='mt-3 bg-white p-4 border border-muted'>
                <div>
                    
                       
                        <div className='mb-2'>
                            <h5 className='text-muted'><i className='fa fa-money'></i> Salary Expectation: (Net)  <i className='fa fa-plus text-danger mx-2 my-2' onClick={handleShow}></i></h5>
                            <h4 className='fw-bold'>{formattedExpectedPay}</h4>
                           
                        </div>

                        

                </div>
            </div>

            {/* Add personal profile */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Personal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>First Name</label>
                            <input type='text'  value={firstName} placeholder='First Name' onChange={(e)=>setFirstName(e.target.value)} name='firstName' className='form-control' />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Last Name</label>
                            <input type='text' placeholder='Last Name'  value={lastName} onChange={(e)=>setLastName(e.target.value)} name='lastName' className='form-control' />
                        </div>
                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger' name='gender'>Gender</label>
                            <select className='form-select' onChange={(e) => setGender(e.target.value)}>
                                <option value={gender}>{gender}</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                        </div>
                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Date of Birth</label>
                            <input type='date' placeholder='Date of Birth' value={moment(dob).format('YYYY-MM-DD')} name='dob' className='form-control' onChange={(e) => setDob(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Nationality</label>
                            <input type='text' placeholder='Nationality' value={nationality} name='nationality' className='form-control' onChange={(e) => setNationality(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>State</label>
                            <input type='text' placeholder='State' className='form-control' name='state' value={state} onChange={(e) => setState(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>City</label>
                            <input type='text' placeholder='City' className='form-control' name='city' value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Phone</label>
                            <input type='text' placeholder='Phone' className='form-control' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Alternate Phone</label>
                            <input type='text' placeholder='Other Phone' className='form-control' name='altPhone' value={altPhone} onChange={(e) => setAltPhone(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger'>Expected Annual Pay</label>
                            <input type='number' placeholder='Expected Annual Pay' value={expectedPay} name='expectedPay' className='form-control' onChange={(e) => setExpectedPay(e.target.value)} />
                        </div>

                        <div className='form-group mb-3'>
                            <label className='form-label lead fs-6 text-danger' >Select most preffered industry</label>
                            <select className='form-select' onChange={(e) => setIndustry(e.target.value)}>
                                <option value={industry}>{industry}</option>
                                <option>Information Technology</option>
                                <option>Agriculture</option>
                            </select>
                        </div>



                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PersonalInfo