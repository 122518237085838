import React from 'react'
import { Link } from 'react-router-dom'
import Spinner1 from './Spinner1'
import MainChat from './chat/MainChat'
let nf = new Intl.NumberFormat('en-US')

const RecentJobSlider = ({ data }) => {
  console.log(data)
if(!data)
return (<Spinner1 />)
  return (
    <><div className='container'>
      <div className='row'>
        <div className='col-md-12 col-lg-12 col-sm-12'>
          <h4 className='text-muted'>Recent Jobs</h4>
          <hr style={{ height: '1px', width: '100%', borderWidth: '0', color: 'red', backgroundColor: '#000000' }}></hr>

          <div>

            {!data ? '' : data.data.rows.map(item => <div className="card p-3 mb-2" key={item.id}>
              <div className='row'>
                <div className='col-md-6'>
                  <h4 style={{ fontWeight: "bold" }}>{item.jobTitle}</h4>
                  <p><i className="fa-solid fa-earth-africa"></i> {item.jobLocation} </p>

                </div>

                <div className='col-md-3'>
                  {item.salaryFrom !== '' ? <p><i className="fa-solid fa-money-check-dollar text-secondary"></i> NGN {nf.format(item.salaryFrom)} </p> : ''}

                  {item.jobType !== '' ? <p><i className="fa-solid fa-clock"></i> {item.jobType}</p> : ''}
                </div>

                <div className='col-md-3 mt-4'>
                  <a href={`/singleJob?id=${item.id}`}><button className='btn btn-outline-danger ml-auto' style={{ width: "100%" }}>View More</button></a>
                </div>
              </div>
            </div>


            )}
            <div className='col-md-3 mt-4 float-end'>
              <a href="/alljobs"><button className='btn ml-auto text-white' style={{ background: '#770f13', width: '100%' }}>All Jobs</button></a>
            </div>
          </div>

        </div>
      </div>
    </div>
   </>
       

  )
}

export default RecentJobSlider