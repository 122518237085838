import React, { useEffect, useState } from 'react'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getCertification, addCertification } from '../../feature/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

const Certification = ({profiles}) => {
    //handle modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //getpayload
    const {certificate } = useSelector((state)=>state.profile)
    const {user} = useSelector((state)=>state.auth)

    //certification state
    const[certName, setCertName] = useState('')
    const[provider, setProvider] = useState('')
    const[certNumber, setNumber] = useState('')
    const[getDate, setDate] = useState('')
    const[hasExecuted, setExecuted] = useState(false)
    const userId = user.id

    //dispatch
    const dispatch = useDispatch()

    const submitCert = (e) =>{
        e.preventDefault()
        let data ={
            certName,
            provider,
            certNumber,
            getDate,
            userId
        }
        dispatch(addCertification(data)).unwrap().then(()=>{
            toast.success('Certification added')
            handleClose()
           setExecuted(true)
        }).catch(toast.error)

    }

    useEffect(()=>{
        async function getCerrt(){
            await dispatch(getCertification())
        }
        getCerrt()
    },[dispatch, hasExecuted])


  return (
    <>
     <div className='mt-3'>
                <h4 className='fw-bold text-muted'>
                    Certifications{' '}
                    <i
                        className='fa fa-plus text-danger mx-2 my-2'
                        style={{ cursor: 'pointer' }}
                        onClick={handleShow}
                    ></i>
                    <a href='/certifications'>
                        <i className='fa fa-edit text-danger mx-2 my-2'></i>
                    </a>
                </h4>
                <div className='mt-4'>
                {!certificate ? (
                    'loading...'
                ) : certificate.length === 0 ? (
                    <div>No certification history added</div>
                ) : (
                    certificate.map((items) => (
                        <div className="d-flex mb-4" key={items.id}>
                            <div className="flex-shrink-0">
                                <div className='big-circle1'>
                                    <i className='fa fa-certificate'></i>
                                </div>
                            </div>
                            <div className="flex-grow ms-3">
                                <div className="flex-column  mb-0">
                                    <h4 className='fw-bold text-danger m-0'>{items.certName}</h4>
                                </div>
                                <div className="mt-2">
                                    <p className='text-muted'>{items.provider}<span className='bg-light text-danger'> {moment(items.getDate).format('LL')}</span> {items.certNumber}</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                </div>
            </div>
    {/* <Card className='job-card mb-4 border-0'>
        <CardHeader className='bg-light border-0'>
            <div className='d-flex float-start'>
                Certifications
            </div>
            <div className='d-flex float-end btn-group'>
                        <i className='fa fa-plus text-danger mx-2 my-2' style={{cursor:'pointer'}} onClick={handleShow}></i>

                       <a href='/certifications'>   <i className='fa fa-edit text-danger mx-2 my-2'></i></a>
                </div>
            
        </CardHeader>
        <div className='card-body'>
        <Row>
                
                   
                    
                {!certificate ? 'loading...' : certificate.length === 0 ? <div>No Cerification history added</div>    : certificate.map(items =>(
                <> 
                <Col md={12} sm={12} className='mb-2'>
                    <div key={items.id}>
                        <p className='lead fw-bold m-0'>{items.certName}</p>
                        <small className='text-muted'>{items.provider}<span className='bg-light text-danger'> {moment(items.getDate).format('LL')}</span></small>
                        <p><small className='text-muted'>{items.certNumber}</small> </p> 
                    </div>
                    </Col>   
                    <hr className='border-danger' />
                </>
                 
                
             
                ))}
                

                
               
                
            </Row>
       
        </div>
    </Card> */}

      {/* Add personal profile */}
      <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Certifications</Modal.Title>
                </Modal.Header>
                <Form>
                <Modal.Body>
                <div className='container'>
                    <div className='form-group mb-3'>
                        <label className='form-label lead fs-6 text-danger'>Certification Name</label>
                        <input type='text' placeholder='Certification Name' className='form-control' required onChange={(e)=>setCertName(e.target.value)} />
                    </div>

                    <div className='form-group mb-3'>
                    <label className='form-label lead fs-6 text-danger'>Provider</label>
                        <input type='text' placeholder='Provider' className='form-control' required onChange={(e)=>setProvider(e.target.value)} />
                    </div>

                    <div className='form-group mb-3'>
                    <label className='form-label lead fs-6 text-danger'>Date Earned</label>
                        <input type='date' placeholder='Date Earned' className='form-control' required onChange={(e)=>setDate(e.target.value)} />
                    </div>

                   
                    <div className='form-group mb-3'>
                    <label className='form-label lead fs-6 text-danger'>Cerification Number</label>
                        <input type='text' placeholder='Certification Number' className='form-control' required onChange={(e)=>setNumber(e.target.value)} />
                    </div>

                    
                </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="primary" onClick={submitCert}>
                        Save Changes
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
    </>
  )
}

export default Certification