import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
let nf = new Intl.NumberFormat('en-US')

const FeaturedJobs = ({data}) => { 
 
    
  //   const [data, setData] = useState([])
  
 

  // const baseURL1='https://backend.nochsolutions.com.ng/jobs/featuredJobs'
 
 


  // useEffect(() => {


  //   const fetchFeatre = () => {
  //     axios.get(baseURL1).then((resf) => {
  //       setData(resf.data)
  //     }) 
  //   }

 
     

  //     fetchFeatre()
      
    
      
      
  //   }, []);



  return (
    <>
     <Header  />
     <div className='container mt-5'>
        <div className='row'>
          
        <div className='col-md-12 col-lg-12 col-sm-12'>
        <h4 className='text-muted'>Featured Jobs</h4>
        <hr style={{ height:'1px', width:'100%', borderWidth:'0', color:'red', backgroundColor:'#000000' }}></hr>
        
        
          <div>
            
            {data.map(items => 
            
            
            <div className="card p-3 mb-2"  key={items.id}>
                <div className='row'>
                    <div className='col-md-12'>
                   <h4 style={{ fontWeight:"bold" }}>{items.job_title} <span className='float-end badge bg-danger' style={{ fontSize: '1rem', fontWeight:'100px' }}>featured</span></h4>
                    <p><i className="fa-solid fa-earth-africa"></i> {items.job_location} </p>
                   
                </div>

                <div className='col-md-12'>
               {items.salary_from !==''?<p><i className="fa-solid fa-money-check-dollar text-secondary"></i> NGN {nf.format(items.salary_from)} </p>:''}

                 {items.job_type !==''? <p><i className="fa-solid fa-clock"></i> {items.job_type}</p> : ''}        
               </div>

               <div className='col-md-5 '>                  
               <Link to={`/singleJob?id=${items.id}`}><button className='btn btn-outline-danger ml-auto' style={{ width:"100%" }} >View More</button></Link>
                </div>
                </div>
           </div>
       
            
            )}
           
          </div>
      
        </div>
        </div>
        </div>
    <Footer />
    
    </>
  )
}

export default FeaturedJobs