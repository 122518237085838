import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../feature/authSlice'
import profileReducer from '../feature/profileSlice'
import webReducer from '../feature/webSlice'
import chatReducer from '../feature/chatSlice'




export const store = configureStore({
  reducer: {
   auth: authReducer,
   profile:profileReducer,
   web:webReducer,
   chat:chatReducer
    
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})
