import {createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"


//get main link
const url = process.env.REACT_APP_BACKEND



const initialState = {
  chats : null,
  messages: null,
}

 //addMessage
 export const addMessage = createAsyncThunk(
  'messages/addMessage',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
      const response = await axios.post(`${url}/messages/`, data, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)


  export const getMessages = createAsyncThunk(
    'chat/getMessage',
    async(data, thunkAPI) => { 
        const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      try{

        const response = await axios.get(`${url}/messages/getMessage/${data.senderId}/${data.recieverId}`, config)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
        
      }
    }
  )



export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {

  },
  extraReducers:  (builder) => {
    (builder)
  
  .addCase(getMessages.fulfilled, (state, action) => {
   
    state.chats = action.payload;
   
  })
  
  


  },
})



export default chatSlice.reducer


