import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../feature/authSlice'

const Logo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutUser = () =>{
    dispatch(logout()).unwrap().then(()=>{
      navigate('/')
    })
  }
  const {user} = useSelector((state)=>state.auth)    
  return (
    
    <nav className="navbar navbar-dark bg-dark navbar-expand-sm">
        <div className='container'>
  <Link to={"/"}>
    <img src='/images/logo.png' alt='Logo' height="50"></img>
  </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbar-list-4">
  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'/'}>Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'/alljobs'}>Jobs</a>
        </li>

        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'https://www.resourceintermediaries.org/about-us/'}>About Us</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'https://www.resourceintermediaries.org/contact-us/'}>Contact Us</a>
        </li>

        {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={'/feedback'}>Feedbacks</a>
        </li> */}

      </ul>
    {user ? 
     <ul className="navbar-nav">
     <li className="nav-item dropdown">
     <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
       <img src="/images/logo.png" width="40" height="40" className="rounded-circle" />
     </a>
     <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
       <a className="dropdown-item" href={'/UserProfile'}>Profile</a>
       <a className="dropdown-item" onClick={logoutUser}>Log Out</a>
     </div>
   </li>   
 </ul> 
    :
    <ul className="navbar-nav text-white">
    <li className="nav-item">
    <a href={'/login'} >Login</a> 
    </li>
    <li className="nav-item"> 
    <a href={'/signup'} >Sign Up</a> 
    </li><i className="fa-solid fa-door-open  text-white"></i>
    </ul>
}

  </div>
  </div>
</nav>
   
  )
}

export default Logo